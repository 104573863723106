<template>
  <!-- 更改客户所有人 -->
  <div class="transfer-client-owner" v-if="transferClientOwnerDialog">
    <el-dialog
      :title="dialogTitle"
      top="5%"
      :close-on-click-modal="false"
      :visible.sync="transferClientOwnerDialog"
      width="40%"
      :before-close="closeTransferOwnerDialog"
    >
      <div
        :style="{
          height: objId === 'account' ? dialogBodyHeight : '120px',
          overflow: 'auto',
          padding: '0 20px',
        }"
      >
        <div class="selectnum" v-show="showSelectNum">
          <!-- 已选择{{ selectNum }}条数据 -->
          {{ $t("vue_label_norm_recordselected", { selectNum: selectNum }) }}
        </div>
        <div class="all_selects" style="position: relative">
          <!-- 所有人类型 -->
          <el-select
            v-model="ownerType"
            filterable
            :placeholder="$t('label.select.please')"
            style="width: 120px"
          >
            <el-option
              v-for="item in ownerTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- 队列选项 -->
          <el-select
            v-model="ownerId"
            clearable
            filterable
            :placeholder="$t('label.select.please')"
            v-if="ownerType === 'queue'"
            style="display: inline-block; width: calc(100% - 120px)"
          >
            <el-option
              v-for="item in ownerQueue"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- 查找/查找多选字段 -->
          <el-select
            clearable
            v-else
            v-model="ownerId"
            class="no-suffix"
            :style="{
              width: 'calc(100% - 120px)',
            }"
            @focus="remoteSearchOwner"
          >
            <p class="searchTipBox">
              <span
                @click="remoteSearchOwner"
                style="display: inline-block; width: 100%"
              >
                <i class="el-icon-search"></i>
                <span style="padding-left: 10px">
                  <!-- 前往精准搜索 -->
                  {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                </span>
              </span>
            </p>
            <el-option
              v-show="ownerName !== ''"
              :key="ownerName"
              :label="ownerName"
              :value="ownerId"
            >
            </el-option>
          </el-select>
          <span class="remoteBtn" v-if="ownerType !== 'queue'">
            <i class="el-icon-search"></i>
          </span>
          <div style="margin-top: 17px" v-if="ownerType !== 'queue'">
            <template>
              <el-checkbox v-model="isSendMail">
                <span style="padding-left: 6px">
                  <!-- 发送电子邮件 -->
                  {{ $t("page.send.email") }}
                </span>
              </el-checkbox>
            </template>
          </div>
          <!-- 有可选选项时才展示 -->
          <div v-if="ownerRaletionList.length > 0">
            <div style="padding-top: 9px">
              <el-button
                type="text"
                size="mini"
                style="padding: 7px 0"
                @click="checkAll(true)"
              >
                <!-- 选择全部 -->
                {{ $t("label.h5.similar.opportunity.select.all") }}
              </el-button>
              <el-button
                type="text"
                size="mini"
                style="padding: 7px 0"
                @click="checkAll(false)"
              >
                <!-- 清除 -->
                {{ $t("label.delete.condition") }}
              </el-button>
            </div>
            <el-checkbox-group
              v-model="checkList"
              style="display: flex; flex-direction: column"
            >
              <el-checkbox
                v-for="item in ownerRaletionList"
                :label="item.label"
                :key="item.value"
              >
                <span class="checkboxs">{{ item.label }}</span>
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="spans" v-html="noticeInfo"></div>
        <!-- 客户时默认展示同步修改的记录 -->
        <div v-if="transferDeault.length > 0" style="margin-top: 30px">
          <ul>
            <li
              v-for="(item, idx) in transferDeault"
              :key="idx"
              class="mtransferli"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeTransferOwnerDialog">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button size="mini" type="primary" @click="saveTransferOwner">
          <!-- 确定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :fieldId="fieldId"
        :checked="checked"
        :ownerType="ownerType"
        :isKnowledge="isKnowledge"
        :relevant-objid="relevantObjId"
        :relevant-prefix="relevantPrefix"
        @changeSelect="changeSelectOwner"
      />
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
/**
 * @description:更改客户所有人
 * 功能：
 * 1、切换用户、队列、伙伴云用户
 * 2、更改记录所有人,可将与此项目相关的记录的所有人一同更改
 * 3、发送电子邮件
 * 逻辑：
 * 1、切换用户、队列、伙伴云选项,对用户进行选择
 * 2、根据记录id查找相关的记录信息,在修改本记录所有人时,若选中相关信息,则一并更改所有人,同时发送群策消息
 * 3、选中用户为非队列时，可选择发送电子邮件,会给绑定邮箱发送邮件
 */
import { queryQueue } from "./api";

export default {
  name: "transfer-client-owenr",
  props: {
    /**
     * 弹窗标题
     */
    dialogTitle: {
      type: String,
      default() {
        return this.$t("label_tabpage_cownerz");
      },
    },
    /**
     * 更改所有人提示语
     */
    noticeInfo: {
      type: String,
      default() {
        return this.$t("vue_label_transferownernotice");
      },
    },
    /**
     * 要更改所有人记录的id
     */
    id: {
      type: String,
    },
    /**
     * 控制更改所有人弹窗显隐
     */
    transferClientOwnerDialog: {
      type: Boolean,
      default: false,
    },
    /**
     * 记录所属对象id
     */
    objId: {
      type: String,
      default: "",
    },
    /**
     * 是否显示 统计已选中数据数量
     */
    showSelectNum: {
      type: Boolean,
      default: false,
    },
    /**
     * 统计已选中数据数量
     */
    selectNum: {
      type: Number,
      default: 0,
    },
    /**
     * 是否知识文章库
     */
    isKnowledge: {
      type: String,
      default: "",
    },
    /**
     * 用户类型:用户、队列、伙伴云用户
     */
    ownerTypeList: {
      type: Array,
      default: () => [],
    },
    /**
     * 可选的其余一起更改所有人的集合
     */
    ownerRaletionList: {
      type: Array,
      default: () => [],
    },
    /**
     * 默认展示同步修改的记录
     */
    transferDeault: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ownerId: "", // 新的所有人id
      ownerName: "", // 新的所有人名称
      checked: false, // 查找所有人弹窗中的表格是否支持多选
      fieldId: "", // 查找字段的id
      relevantPrefix: "", // 查找字段所属对象前缀
      relevantObjId: "", // 查找字段所属对象id
      showSearchTable: false, // 控制查找字段弹窗显隐
      checkList: [], // 选中的其余一起更改所有人的集合
      isSendMail: false, // 是否发送邮件
      ownerQueue: [], // 队列集合
      ownerType: "user", // 用户类型 ‘user’:用户 ‘queue’:队列 ‘partneruser’:伙伴云用户
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`, // 弹窗主体高度
    };
  },
  mounted() {
    this.ownerId = "";
    this.ownerName = "";
    this.ownerType = "user";
    this.checkList = [];
    this.$bus.$on("windowResize",this.windowResizeCallback);
  },
  beforeDestroy(){
    this.$bus.$off("windowResize",this.windowResizeCallback);
  },
  methods: {
    windowResizeCallback(offsetHeight){
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    /**
     * 查询队列数据
     */
    queryQueue() {
      queryQueue({ id: this.objId }).then((res) => {
        if (res.result && res.returnCode === "1") {
          this.ownerQueue = res.data === null ? [] : res.data;
        } else {
          this.$message.error(res.returnInfo);
        }
      });
    },
    /**
     * 查找所有人
     */
    remoteSearchOwner() {
      // 查找所有人数据写死
      this.fieldId = "ffe100000001265aWyi8";
      this.relevantObjId = "user";
      this.relevantPrefix = "005";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 查找多选弹框中选择的用户数据
     * @param {row} 选中的数据
     */
    changeSelectOwner(row) {
      this.ownerName = row.data && row.data.name ? row.data.name : "";
      this.ownerId = row.data && row.data.id ? row.data.id : "";
      this.showSearchTable = false;
    },
    /**
     * 关闭更改所有人弹窗
     */
    closeTransferOwnerDialog() {
      this.ownerId = "";
      this.ownerName = "";
      this.ownerType = "user";
      this.checkList = [];
      this.isSendMail = false;
      this.$emit("closeTransferOwnerDialog");
    },
    /**
     * 确认更改所有人
     */
    saveTransferOwner() {
      /**
       * isSendMail: 是否发送邮件，发送传true
       * recordId:	记录ID
       * newOwnerId:	新所有人ID
       * relatedIds: 相关列表字段ID，逗号分隔
       * recordName: 记录名称
       */
      if (this.ownerType === "queue") {
        this.isSendMail = false;
      }
      let optionVal = [];
      this.checkList.forEach((element) => {
        let option = this.ownerRaletionList.find((item) => {
          return item.label === element;
        });
        optionVal.push(option.value);
      });
      if (this.ownerId === "") {
        // 请选择所有人
        this.$message.warning(this.$i18n.t("batch.change.owner"));
      } else {
        // 更改记录所有人
        this.$emit(
          "saveTransferOwner",
          this.isSendMail.toString(),
          this.id,
          this.ownerId,
          optionVal.toString()
        );
      }
    },
    /**
     * 选中全部相关列表
     * @param {flag} 选中状态 true表示选中 false代表取消选中
     */
    checkAll(flag) {
      this.checkList = [];
      if (flag) {
        this.ownerRaletionList.forEach((item) => {
          this.checkList.push(item.label);
        });
      }
    },
  },
  watch: {
    /**
     * 当用户类型切换为队列时,查询可选队列记录
     * @param {nval} 新的用户类型
     */
    ownerType(nval) {
      this.ownerId = "";
      this.ownerName = "";
      if (nval === "queue") {
        this.$nextTick(() => {
          this.queryQueue();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  display: none;
}
.remoteBtn {
  position: absolute;
  right: 8px;
  top: 5px;
  color: #c0c4cc;
  z-index: 100;
}
.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  color: #606266 !important;
}

::v-deep .el-input__inner {
  height: 30px;
}

.selectnum {
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  padding-bottom: 22px;
}
.all_selects {
  ::v-deep .el-select:first-child {
    .el-input__inner {
      border-radius: 4px 0 0 4px;
    }
  }
  ::v-deep .el-select:nth-child(2) {
    margin-left: -1px;
    .el-input__inner {
      border-radius: 0 4px 4px 0;
    }
  }
}

.checkboxs {
  margin-left: 6px;
  font-size: 12px;
  line-height: 28px;
}

.spans {
  height: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-top: 12px;

  .spancolor {
    color: #fa6400;
  }
}

.mtransferli {
  list-style: inside;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #0c0b0b;
  line-height: 18px;
}
</style>
